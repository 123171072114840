h1.title {
  text-shadow: 1px 1px 8px rgba(0, 0, 0, 0.75);
  color: white;
  font-size: 5vw;
  font-family: "Rockwell";
  text-align: center;
}
p {
  color: white;
  font-size: 2vw;
  font-family: "Rockwell";
}
label {
  color: white;
  font-size: 1vw;
}

.field {
  height: 30px;
  width: 200px;
  border-radius: 5px;
}
.button {
  height: 30px;
  width: 50px;
  border-radius: 5px;
  margin-left: 5px;
}
.small {
  color: #61dafb;
  font-size: 1vw;
}

body {
  background: linear-gradient(to top, #4c577b, #95d8f5) fixed;
}

img {
  max-width: 100%;
  height: auto;
}

.App {
  text-align: center;
}

.Screenshot {
  height: 30vmin;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-bottom: 1rem;
  pointer-events: none;
}

.Apple-badge-logo {
  height: 5vmin;
  padding: 0.25rem;
  padding-left: 0.5rem;
  pointer-events: none;
}
.Google-badge-logo {
  height: 5vmin;
  padding: 0.25rem;
  pointer-events: none;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-link {
  color: #61dafb;
}
